.main__body-mapData {
  background: var(--background-4);
  height: 100vh;
}

.main__mapData-header {
  font-size: var(--font-size-2);
  position: relative;
  color: var(--font-color-2);
  background: var(--background-6);
  background-color: var(--background-3);
  padding: 0.8rem 3rem 0.8rem 0.5rem;
}

.data__headline {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-3);
  position: relative;
  color: var(--font-color-2);
  background: none;
}

.find__your-location {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-3);
  position: absolute;
  color: var(--font-color-2);
  background: none;
  padding: 1rem 0rem;
  right: 3rem;
  z-index: 100;
}

/* Mapbox branding */
.mapboxgl-ctrl-logo {
  width: 0px;
  height: 0px;
}

a.mapboxgl-ctrl-logo {
  width: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib-button {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  border-radius: 0px;
  margin: 0px;
  min-height: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
}

.MarkerText {
  color: #fff;
  display: none;
}

/* Pop-up-------------------------------------------- */

.mapboxgl-popup-content {
  width: 18rem;
  font-size: var(--font-size-4);
  position: relative;
  color: var(--font-color-2);
  background: var(--accent-8);
  cursor: crosshair;
  padding: 0.5rem 1.5rem;
}



/* Button ------------------------- */

#preview-Image{
  /* min-width: 50vw; */
  max-height: 50vh;
  max-width: 50vw;

  /* height: 100px; */
  border: 3px solid white;
  /* position: absolute;
  top:0;
  left:0; */
  /* z-index: 10000; */
  display: block;
  border-radius: 5px;
  opacity: 0.8;
  transform: perspective(1000px) translateZ(0);
  transition: 0.1s ease-out;
}
.previewImageFound{
  opacity: 0 !important;
  perspective:perspective(1000px) translateZ(-40px) !important;
}
/* #arjs-video{
  display: block !important;
  opacity: 1 !important ;
} */

location-back{
  z-index: 100002 !important;
  width: 100vw;
  height: 100vh;  
  position: absolute;
  top:0;
  left:0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  transform-style: preserve-3d;

}
location-pop-up{
  user-select: none;
  width: 80vw;
  height:200px;
  position: absolute;
  /* background-color: rgb(40,40,40); */
  /* border: 1px solid red; */
  transform: rotateX(45deg) rotateY(23deg);
  transform-style: preserve-3d;
  pointer-events: all;
  opacity: 1;
  scale: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 5px; */
  pointer-events: all;
}
.focus-pop-up{
  transform:translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg)  !important;
  border: 3px solid rgb(100,100,100);
}
.focusTransition{
  transition: 0.3s cubic-bezier(.03,.66,.55,.96);
}
.hideElm{
  display: none !important;
}
.hidePopUp{
  
  pointer-events: none;
  opacity: 0 !important;
  scale: 0.8;
}
pop-up-shadow{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  box-shadow: rgb(0,0,0,1) 0 0 25px;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
  background-color: black;
  display: none;
}
sub-pop-up{
  width: 100px;
  height: 100px;
  background-color: rgb(60,60,60);
  position: absolute;
  transform-style: preserve-3d;

  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  opacity: 0.9;
  font-size: 10px;
}
#sub1{
  width: 80px;
  height: 80px;
  top:50px;
  left:-60px;
  transform: translateZ(75px);

}

#sub2{
  width: 75px;
  height: 130px;
  top:75px;
  right:-50px;
  transform: translateZ(125px);

}
location-name{
  width: 100%;
  height: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  transform-style: preserve-3d;
  position: absolute;
  top:-90px;
  z-index: 10000;
  text-align: center;
}

.ar-layer{
  /* width: 100%; */
  max-width: 80vw;
  position: absolute;
  transform-style: preserve-3d;
  /* transform: translateY(0px); */
  /* transition: 5s cubic-bezier(.04,.63,.4,.98); */
  /* border: 1px solid red; */
  pointer-events: none;
}

audio{
  position: absolute;
  transform-style: preserve-3d;
  transform: translateZ(190px);
  top: 200px;
  pointer-events: all;
  /* scale: 2; */
  width: 50vw;
  touch-action: manipulation;
}
audio.ar-layer{
  pointer-events: all;
  max-width: initial;
}
ar-content{
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 80vw;
  max-height: 80vh;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
}
back-button{
  position: absolute;
  top:0;
  left:0;
  margin: 20px;
  background-color: white;
  color: black;
  padding:5px 10px 5px 10px;
  border-radius: 5px;
  z-index: 100003 !important;
}
back-button::focus{
  color: white;
  background-color: black;
}
.a-canvas{
  pointer-events: none;
}
.cameraUnderlay{
  /* width: 100%;
  height: 100%; */
  position: absolute;
  display: block;

  background-size: cover;
}
camera-container{
  width: 100%;
  height: 100%;
  position: absolute;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.arjs-loader{
  z-index: 100003 !important;
  background-color: black;
  opacity: 1;
}