.main__body-contact {
  background: var(--background-4);
  min-height: 100vh;
  position: absolute;
  width: 100vw;
}

.main__contact-header {
  font-size: var(--font-size-1);
  color: var(--font-semiTransparent);
  position: absolute;
  margin: 6.5rem 3rem;
  /* top: 10rem auto; */
  /* text-align: center;   */
  z-index: 10;
}

#Contact__form {
  width: 75%;
  /* font-family: var(--font-family-2); */
  padding: 50px 0px;
  margin: 2rem auto;
  margin-bottom: 8rem;
  box-sizing: border-box;
  position: relative;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

#feedbackType,
#lastName,
#telephone,
#email {
  width: 100%;
  margin: 5px auto;
  font-size: var(--font-size-4);
  color: var(--font-color-4);
  border: 1px solid var(--background-3);
  background: none;
  font-family: monospace;
}
#feedbackType option{
  background:var(--background-4) ;
  padding-top: 10px;
  font-family: monospace;
}

.Contact__form-feedbackType{
  font-size:15px;
  line-height: 10px;
  margin-bottom: 20px;
}

#Contact{
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}
/* general styling across the inputs */
input {
  padding: 10px;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  border: 1px solid var(--background-3);
  transition: all 0.3s;
}

input:focus:required:invalid {
  border: 2px solid var(--border-color-2);
  box-shadow: var(--box-shadow-3);
}

input:focus:required:placeholder-shown {
  border: 2px solid var(--border-color-2);
}

#comments {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin: 20px 0px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-size: var(--font-size-4);
  font-family: monospace;
  color: var(--font-color-2);
  transition: all 0.3s;
  border: 1px solid var(--background-3);
  /* box-shadow: var(--box-shadow-1); */
}

#comments::placeholder {
  font-size: var(--font-size-4);
}
#comment:focus:required:invalid {
  border: 2px solid var(--border-color-2);
  box-shadow: var(--box-shadow-3);
}
#Contact__form-fileInput{
  width: 100%;
  margin-bottom: 10px;

}
#preview-img{
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#Contact__form-submit-Btn {
  padding: 8px 12px;
  background: var(--background-3);
  font-size: var(--font-size-3);
  color: var(--font-color-1);
  margin: 0.1rem 0px;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: 0.8s;
  box-shadow: var(--box-shadow-1);
  font-weight: var(--font-weight-2);
  color: white;
}

#Contact__form-submit-Btn:hover {
  background: var(--background-2);
  color: var(--font-color-2);
}

::placeholder {
  color: var(--font-color-4);
}

/* MEDIA  -------------------------------------------- */

@media screen and (min-width: 700px) {
  /* @media (width <= 700px) or (orientation: landscape ) { */
  .main__body-contact {
    padding: 0px 10%;
  }
  .main__contact-header {
     margin: 5.5rem 3rem;
    font-size: 2rem;
    
  }

  #Contact__form {
    max-width: 60%;
    padding: 40px 0px;
    margin: 6rem auto;
    box-sizing: border-box;
    position: relative;
  }

  #feedbackType,
  #lastName,
  #telephone,
  #email,
  #comments {
    padding: 3%;
    box-shadow: var(--box-shadow-1);
  }
  #Contact__form {
    font-size: 2.5rem;
  }
  #Contact__form-submit-Btn {
    font-size: 1.2rem;
  }
  ::placeholder {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1080px) {
  .main__contact-header {
    padding: 0px 10%;
  }

  .main__contact-header {
     margin: 5.5rem 3rem;
    font-size: 2rem;    
  }

  #Contact__form {
    max-width: 60%;
    padding: 40px 0px;
    margin: 6rem auto;
    box-sizing: border-box;
    position: relative;
  }

  #feedbackType,
  #lastName,
  #telephone,
  #email,
  #comments {
    padding: 3%;
    box-shadow: var(--box-shadow-1);
  }
  #Contact__form {
    font-size: 2.5rem;
  }
  #Contact__form-submit-Btn {
    font-size: 1.2rem;
  }
  ::placeholder {
    font-size: 1.4rem;
  }
}
rating-text{
  width: 100%;
  height: 20%;
  position: relative;
  display: flex;
  align-items:end;
  color: rgb(180,180,180);
}
rating-button{
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  justify-content: center;
 
}
rating-button >img{
  margin: 10px;
  filter: hue-rotate(273deg) saturate(0) brightness(0%) ;
  width: 10%;
  transition: 0.1 ease-in-out;
}
.select-star{
  filter:hue-rotate(70deg) brightness(300%);

}