.main__body-mapData {
  background: var(--background-4);
  height: 100vh;
}

.main__mapData-header {
  font-size: var(--font-size-2);
  position: relative;
  color: var(--font-color-2);
  background: var(--background-6);
  background-color: var(--background-3);
  padding: 0.8rem 3rem 0.8rem 0.5rem;
}

.data__headline {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-3);
  position: relative;
  color: var(--font-color-2);
  background: none;
}

.find__your-location {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-3);
  position: absolute;
  color: var(--font-color-2);
  background: none;
  padding: 1rem 0rem;
  right: 3rem;
  z-index: 100;
}

/* Mapbox branding */
.mapboxgl-ctrl-logo {
  width: 0px;
  height: 0px;
}

a.mapboxgl-ctrl-logo {
  width: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib-button {
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  width: 0px;
  height: 0px;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: 0;
  border-radius: 0px;
  margin: 0px;
  min-height: 0px;
  padding: 0px;
  width: 0px;
  height: 0px;
}

.MarkerText {
  color: #fff;
  display: none;
}

/* Pop-up-------------------------------------------- */

.mapboxgl-popup-content {
  width: 18rem;
  font-size: var(--font-size-4);
  position: relative;
  color: var(--font-color-2);
  background: var(--accent-8);
  cursor: crosshair;
  padding: 0.5rem 1.5rem;
}


.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  /* align-self: center; */
  border-bottom: none;
  border-top-color: var(--accent-8);
  /* border-bottom-color: var(--accent-8); */
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  /* align-self: center; */
  border-top: none;
  /* border-top-color: var(--accent-8); */
  border-bottom-color: var(--accent-8);
}

.mapboxgl-popup-tip {
  
  border: 20px solid transparent;
  height: 0;
  width: 0;
  z-index: 1;
  margin-top:-2px;
}

/* Button ------------------------- */
.mapboxgl-popup-close-button {
  font-size: var(--font-size-2);
  background-color: var(--accent-8);
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 1.5rem;
  border-radius: 5px;
}

.mapboxgl-popup-close-button:hover {
  transition: 0.8s;
  background-color: var(--accent-8);
}

.mapboxgl-popup-close-button:focus {
  outline: none;
}

.location-preview{
  height: auto; 
  width: auto; 
  max-height: 100px;
  min-height: 100px;
  height: fit-content;
 
  position: relative;
  /* top:0;
  left:0; */
  /* z-index: 10000; */
  display: inline-block;
  border-radius: 5px;
  margin-left: 10px;
  margin-right:10px;
  transition: 0.2s ease-out;
  opacity: 1;
  transform: translateY(0px);
  border: 3px solid white;
  transform-origin: 50% 50%;
  cursor: pointer;
  pointer-events: all;
}
.thumbnail-big{
  max-height: 150px;
  min-height: 150px;
  margin-top: -50px;
}
.loc-prev-start{
  opacity: 0;
  transform: translateY(30px);
  
}

preview-collection{
  width: 100vw;
  height: 180px;
  position: fixed;
  bottom:var(--nav-height);
  /* display: flex;
  justify-content: right; */
  /* border: 1px solid green; */
  overflow-y: none;
  overflow-x: auto;
  white-space: nowrap;
  /* float:right; */
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: none;
  padding-top: 60px;
  display: flex;
  align-items: bottom;
  justify-content: center
  ;
}
preview-collection::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  pointer-events: all;
}
/* Track */
preview-collection::-webkit-scrollbar-track {
  background:none;
  pointer-events: all;
}

/* Handle */
preview-collection::-webkit-scrollbar-thumb {
  background: rgb(255,255,255,0.2);
  border-radius: 5px;
  pointer-events: all;
  
}

/* Handle on hover */
preview-collection::-webkit-scrollbar-thumb:hover {
  background: rgb(255,255,255,1);
}
preview-collection::-webkit-scrollbar-thumb:active {
  background: rgb(255,255,255,1);
}


main-map{
  position: absolute !important;
  /* top:80px; */
  left:0;
  width: 100%;
  height: 100%;
 
}
.mapboxgl-canvas{
}
.mapboxgl-canvas{
  /* border: 1px solid red; */
}
:root{
  --marker-colour: rgb(64,177,207);
}
.marker {
  background-color: var(--marker-colour);
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0,0,0,0.1) 0 0 5px 5px ;
}
marker-pointer{
  width: 8px;
  height: 8px;
  background-color: inherit;
  position: absolute;
  rotate: 45deg;
  transform-origin: 50% 50%;
  bottom: -2px;
  /* left:5px; */
  /* background-color: red; */
}
.marker-flag{
  width: 40px;
  height: 25px;
  filter: grayscale(0%);
  /* border: 2px solid white; */
  position: relative;
  display: block;
  outline: 3px solid white;
  border-radius: 5px;

  margin-top:-12px;
}
marker-center{
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
}
marker-text{
z-index: 10;
}

.mapboxgl-popup {
  max-width: 200px;
  }
.mapboxgl-user-location-accuracy-circle{
  pointer-events: none;
}
rating-back{
  width: 100%;
  height: 100%;
  position:fixed;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: 0.1s ease-in-out;
}
rating-pop-up{
  width: 80vw;
  height: 180px;
  background-color: rgb(40,40,40);
  position: absolute;
  display: block;
  border-radius: 10px;
  color: white;
  /* display: flex;
  justify-content: center; */
  overflow: hidden;
}

rating-options{
  width: 100%;
  height: 30%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(50,50,50);
}

rating-option{
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
rating-option:active{
  background-color: rgb(70,70,70);
}

.hideReviewBack{

  opacity: 0;
  pointer-events: none;
}

