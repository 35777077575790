
.Primary__navbar-link {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: auto;
  /* background: var(--background-6); */
}


.Primary__navbar-link a {
  float: left;
  display: block;
  color: var(--background-1);
  text-align: center;
  padding: 14px 6.5%;
  text-decoration: none;
  font-size: 17px;
  /* for 6 icons */
  width: 60px;  
  height: 60px;
  /* for 5 icons */
  /* width: 20%; */
  /* for 4 icons */
  /* width: 25%; */
  transition: all 0.1s;
  bottom: 0;
  /* position: absolute; */
  border-radius: 5px 5px 0 0 ;
}

.Nav__icon {
  width: 20px;
  padding: 0px;
  z-index: 100;
}

.Primary__navbar-link a:hover {
  background: var(--primary-col);
}

.Primary__navbar-link a.active {
  background: var(--primary-col);
}

.Primary__navbar-link .icon {
  display: none;
}

/* @media screen and (max-width: 300px) {
  .Primary__navbar-link a:not(:first-child) {
    display: none;
  }
  .Primary__navbar-link a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 300px) {
  .Primary__navbar-link.responsive .icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .Primary__navbar-link.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
} */


:root {
  --nav-height: 70px;
}

.primary-navbar{
  width: 100%;
  height: var(--nav-height);
  /* border: 1px solid red; */
  bottom:0;
  position: absolute;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.hideNav{
  display: none;
}

#main-nav-button{
  
}
nav-menu{
  /* border: 1px solid red; */

  position: relative;
}
.profile-pic{
  width:50px;
  height: 80px;
  border: 3px solid white;
  border-radius: 50%;
}