

.Card__logo {
  display: relative;
  text-align: center;
}

.Card__logo > img {
  display: relative;
  margin-top: 8rem;
  height: 10rem;
  filter: drop-shadow(0.1rem 0.2rem 0.3rem var(--accent-6));
}

.Card__text-projectName {
  font-size: var(--font-size-1);
  color: var(--font-semiTransparent);
  font-weight: var(--font-weight-1);
  display: relative;
  margin-top: 0rem;
}

.Card__text-city {
  margin-top: 1rem;
  font-size: var(--font-size-3);
  display: block;
}

.Card__text-projectYear {
  font-size: var(--font-size-4);
  /* color: var(--font-semiTransparent); */
  margin: 1.5rem;
  position: relative;
  display: block;
}

/* .Card__text-Socials {
  font: 1.2rem var(--font-family-1);
  position: relative;
  display: block;
  margin-top: 20px;
} */

.Card__text-developer {
  font: var(--font-family-1);
  font-size: var(--font-size-4);
  position: relative;
   margin-top: 1.5rem;
  /* margin: 20px 0px 0px 0px; */
  display: block;
}

.Card__QR {
  display: relative;
}

.Card__QR > img {
  display: relative;
  margin-top: 1rem;
  height: 6rem;
  /* filter: invert(100); */
}

/* MEDIA  -------------------------------------------- */

@media screen and (min-width: 700px) {
 
.Card__logo > img {
  display: relative;
  margin-top: 6rem;
  height: 10rem;
  filter: drop-shadow(0.1rem 0.2rem 0.3rem var(--accent-6));
}

.Card__text-projectName {
  font-size: var(--font-size-1) 
}

.Card__text-city {
  margin-top: 1rem;
  font-size: var(--font-size-3);
  display: block;
}

.Card__text-projectYear {
  font-size: var(--font-size-4);
  /* color: var(--font-semiTransparent); */
  margin: 1.5rem;
  position: relative;
  display: block;
}

.Card__text-developer {
  font: var(--font-family-1);
  font-size: var(--font-size-4);
  position: relative;
  margin-top: 1.5rem;
  display: block;
}

.Card__QR {
  display: relative;
}

.Card__QR > img {
  margin-top: 1rem;
  height: 7rem-0.5rem;
}
}


.title{

  /* margin-top: 10px; */
  width: 60vw;
  text-wrap: auto;
  /* flex-wrap: wrap; */
  /* display: flex;
  justify-content: center; */
  position: relative;
  /* border-left: 8px solid white; */
  /* padding-left: 50px;   */
  display: block;
  /* mix-blend-mode:soft-light;; */
}
.cardP {
  background-color: var(--background-2);
  min-height: 100vh;
  color: var(--font-color-2);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 1px solid red ; */

}

title-card{

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  /* text-align: center; */
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
  margin-top:-150px;

}
sign-ins{

  width: 200px;
  position: absolute;
  /* display: table; */
  /* position: relative; */
  margin-top: 20px;
  /* display: flex; */
  /* float: left; */
  left:calc(50% - 100px)
  
}
.login-with-google-btn{
  display: table-cell;
  margin-bottom: 20px;
  float: right;
}
.login-with-facebook-btn{
  display: table-cell;
 
  float: right;
}
.home-image{
  position: absolute;
  max-height: 80vh;
  max-width: 90vw;
  /* height: 500px; */
  margin-top: -150px;
  /* opacity: 0.8; */
  mix-blend-mode: difference;
  opacity: 0.4;
  pointer-events: none  ;


}

.arHiveLogo{
  position: absolute;
  width: 50px;
  margin-top: 200px;
  filter: invert(100%);
    mix-blend-mode: difference;
  
}