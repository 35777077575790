@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
}
 
:root {

  
  --primary-col:#e8e517cc;
  
  --background-1: #ffff;
  --background-2: rgb(40,40,40);
  --background-3: #e8e517cc;
  --background-4: #424d5c;
  --background-5: #5d757e;
  --background-6: url(./components/img/grid.png);

  --accent-1: #8D8B0Dcc;
  --accent-2: #e8e517cc;
  --accent-3: #00ff00;
  --accent-4: #545455;
  --accent-5: #ffff;
  --accent-6: rgba(255, 255, 255, 0.74);
  --accent-7: #f509ae;
  --accent-8: #3fb1ce;
  

  /* scale from mobile to desktop base 16 */
  --font-size-1: 1.8rem; /*28.8px*/
  --font-size-2: 1.3rem; /* 20.8px*/
  --font-size-3: 1.2rem; /*19.2px*/
  --font-size-4: 1rem; /* 16 px = 1 rem */
  --font-size-5: 0.8rem; /*12.8px*/

  --font-weight-1: 700;
  --font-weight-2: 600;
  --font-weight-3: 400;

  --font-color-1:  #424d5c;
  --font-color-2: #ffff;
  --font-color-3: #000;
  --font-color-4: #d7d7db;
  --font-color-5: #00ff00;
  --font-semiTransparent: #ffffff80;

  /* styling */
  --box-shadow-1: 6px 6px 6px 0px var(--accent-1);
  --box-shadow-2: 0 12px 12px var(var(--accent-3));
  --box-shadow-3: 0 0 6px #fb00ff99;

  /* Border */
  --border-color-1: #888787;
  --border-color-2: #fb00ff99;

  --border-style-1: 1px solid #d3d3d3;
  --border-radius-1: 20px;

  --border-radius-2: 3px;

}
#root{
  position: absolute;

  width: 100%;
  height: calc(100% - 40px);
  bottom: 0;
  overflow: hidden;
  /* border: 10px solid blue; */
}
beta-banner{
  width: 100vw;
  height: 40px;
  background-color: var(--accent-2);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  /* font-weight: 900; */

  font-size: 15px;
  
  white-space: nowrap;
  
}


@media screen and (min-width: 1600px) {
  beta-banner {
    /* border:1px solid red; */
     font-size: 30px;
  }
}
beta-banner span{
  white-space: normal;
}
#beta-link{
  white-space:no-wrap;
  display: in;
  margin-left: 4px;
  text-decoration: underline;
  color: rgb(0, 0, 194); 
  cursor: pointer;
  
}
body {
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
 font-family: "Share Tech Mono", monospace;
 margin:0 !important;
 overflow: hidden !important;
}

html {
  color-scheme: dark light;
}
 
a-scene{
  position: absolute;
  top:0;
  left:0;
  /* border: 5px solid red;  */
  z-index: 100001 !important;
  opacity: 0; 
  overflow: hidden;
  pointer-events: none;
  /* background-color: rgba(255,0,0,0.2); */
}
.show{
  pointer-events: auto;
  opacity:1 ! important;
}

#arjsDebugUIContainer{
  z-index: 100001 !important;
}
.arjs-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arjs-loader div {
  text-align: center;
  font-size: 1.25em;
  color: white;
}
a-entity{
   
}
/* a-camera{
  border:2px solid green;
  width: 100px;
  height: 100px;
  position: absolute;
 
} */

#arjs-video{
  z-index: 100000 !important;
   /* display: none ; */
   opacity: 0;
  /* height: 100vh !important;
  width:100vw !important; */
  scale: 10vh;
  /* width: 100vw !important ; */
  position: absolute;
  top:0 !important;
  left:0 !important;
  margin:0 !important;  
   background-color: black;
   pointer-events: none;
}

camera-info-back{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top:0;
  left:0;
  /* display:flex */
}
preview-container{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#ArPopName{
  width: 100%;
  text-align: center;
  font-size: 20px;
  text-shadow:  2px 2px 0px black;
}



.arjs-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arjs-loader div {
  text-align: center;
  font-size: 1.25em;
  color: white;
}
.googleLogin{
  background-color: white;
  font-family: "Share Tech Mono", monospace;
  color: black;
  width: 200px;
  border-radius: 3px;
  height: 40px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signImg{
  width: 20px;
  height: 20px;
  background-image: url("/public/google.png");
  background-size: cover;
  image-rendering:crisp-edges !important;
}
